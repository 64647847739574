import { render, staticRenderFns } from "./customised_theme_design.vue?vue&type=template&id=a85b964a&scoped=true&"
import script from "./customised_theme_design.vue?vue&type=script&lang=js&"
export * from "./customised_theme_design.vue?vue&type=script&lang=js&"
import style0 from "./customised_theme_design.vue?vue&type=style&index=0&id=a85b964a&prod&scoped=true&lang=css&"
import style1 from "./customised_theme_design.vue?vue&type=style&index=1&id=a85b964a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a85b964a",
  null
  
)

export default component.exports